import mainjson from '../config/main';
export default function GetData() {
        return {
        "apitype":"levels",
        "id":"level_id",
        "subidname":"level_event_id",
        "options":["edit","insert","delete"],
        "nameSingle":"level",
        "nameMultiple":"levels",
        "filters":
            [
               
            ]
        ,
        "fields":{
            "field1":{
                "name":"Level",
                "field":"level_level",
                "type":"TextInput",
                "subtype":"Number",
                "required":true,
                "list":true,
            },
            "field2":{
                "name":"Name",
                "field":"level_name",
                "type":"TextInputMultiLanguage",
                "required":true,
                "list":true
            },
            "field3":{
                "name":"Description",
                "field":"level_description",
                "type":"TextAreaMultiLanguage",
                "required":true,
                "list":false
            },     
        }
    }
}